import React from 'react';
import Layout from '../components/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SEO } from "../components/Seo";
import styled from 'styled-components';
import ZawodyInfo from '../components/ZawodyInfo';

const HeroSection = styled.section`
  position: relative;
  width: 100%;
  height: 80vh; /* Wyższe hero na desktop */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 80vh; /* Pełna wysokość ekranu na mobilach */
  }

  .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  h1 {
    font-size: 1.8em;
    font-weight: bold;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  }
  
  p {
    font-size: 1em;
    font-weight: 300;
    max-width: 800px;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
  }

  ${({theme}) => theme.media.tablet} {
    h1 { font-size: 3em; }
    p { font-size: 1.5em; }
  }
`;

const Section = styled.section`
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;

  h2 {
    font-size: 2em;
    font-weight: bold;
    color: #B68A65;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 1.2em;
    font-weight: 300;
    margin-bottom: 20px;
  }
`;

const StyledTable = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 1.1em;
    background: white;
    border-radius: 10px;
    overflow: hidden;
  }

  th, td {
    padding: 16px;
    text-align: left;
  }

  th {
    background: #B68A65;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  tr:nth-child(even) {
    background: #f8f8f8;
  }

  td:first-child {
    font-weight: bold;
  }

  /* Responsywność tabeli - zamienia tabelę na listę */
  @media (max-width: 768px) {
    table, thead, tbody, th, td, tr {
      display: block;
    }

    thead {
      display: none;
    }

    tr {
      margin-bottom: 10px;
      border-bottom: 2px solid #B68A65;
      padding: 10px;
    }

    td {
      text-align: left;
      padding: 10px;
      font-size: 1em;
      display: flex;
      justify-content: space-between;
    }

    td:first-child {
      font-weight: bold;
    }
  }
`;

const GallerySection = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  text-align: center;

  h2 {
    font-size: 2em;
    font-weight: bold;
    color: #B68A65;
    margin-bottom: 20px;
  }
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 0; /* Brak odstępów między zdjęciami */

  @media (max-width: 768px) {
    display: none; /* Ukryta na mobilach */
  }

  .gallery-item {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const MiasteczkoSection = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  text-align: center;

  h2 {
    font-size: 2em;
    font-weight: bold;
    color: #B68A65;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2em;
    font-weight: 300;
    margin-bottom: 15px;
    text-align: justify;
  }

  ul {
    list-style: none;
    padding: 0;
    font-size: 1.2em;
    font-weight: 300;
  }

  ul li {
    margin-bottom: 10px;
  }

  a {
    color: #B68A65;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s;
  }

  a:hover {
    color: #8B5E3B;
  }

  @media (max-width: 768px) {
    text-align: left;
    p, ul {
      font-size: 1em;
    }
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  background-color: #4CAF50; /* Zielony kolor */
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  padding: 15px 30px;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #388E3C; /* Ciemniejszy zielony przy hover */
  }
`;

const Zawody = () => {
    return (
        <Layout>
            <SEO title="Zawody Ultra Race Dolina Bugu" description="Dołącz do Ultra Race Dolina Bugu i zmierz się z wyjątkową trasą gravelową!" />

            <HeroSection>
                <StaticImage
                    src="../images/hero_ultra_race.jpg"
                    alt="Ultra Race Dolina Bugu - ultramaraton rowerowy"
                    placeholder="blurred"
                    layout="constrained"
                    // width={1600}
                    // height={900}
                    className="hero-image"
                />
                <div>
                    <h1>ULTRA RACE DOLINA BUGU 2025</h1>
                    <p>Start, meta, rywalizacja i niezapomniane emocje. Wybierz dystans i podejmij wyzwanie!</p>
                    <CTAButton href="https://b4sportonline.pl/ultra_race/" target="_blank" rel="noopener noreferrer">
                Dołącz do wyzwania! 🚴‍♂️
            </CTAButton>
                </div>
            </HeroSection>

            <Section>
                <MiasteczkoSection>
                <h2>📍 Miasteczko Zawodów | Mielnik</h2>
                <p>
                    Główne centrum zawodów znajdziesz w Mielniku, gdzie startują dystanse Classico (290 km) i Piccolo (140 km) oraz znajduje się meta dla wszystkich zawodników**.  
                    Gigante (550 km) startuje w Chełmie, ale meta również jest wspólna.
                </p>
                <h3>🔥 Co czeka na Ciebie w miasteczku?</h3>
                <ul>
                    <li>🎉 Strefa kibica 🍲🥟</li>
                    <li>🚴‍♂️ Miejsca do odpoczynku i regeneracji zawodników</li>
                    <li>🏆 Dekoracja zwycięzców i uroczyste witanie finisherów</li>
                    <li>🎭 Atrakcje turystyczne – muzea, urokliwe miejsca, dziewicza natura i oaza spokoju nad Bugiem.  
                    <br/>📍 Sprawdź mapę atrakcji turystycznych:  
                    <a href="https://www.google.com/maps/d/viewer?mid=14MwHsw7OaiWGS9TAtDHlBBfMqT_4AYwb&femb=1&ll=52.39348458025056%2C22.7596266450304&z=10" target="_blank" rel="noopener noreferrer">
                        Otwórz mapę 🗺️
                    </a>
                </li>
                </ul>
            </MiasteczkoSection>
                <h2>📆 Harmonogram zawodów</h2>
                <StyledTable>
                    <table>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Chełm</th>
                                <th>Mielnik</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>04.09.2025 (Czwartek)</td>
                                <td>Before Party dla Gigante - wydawanie pakietów</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>05.09.2025 (Piątek)</td>
                                <td>Start Gigante</td>
                                <td>Before Party dla Classico - wydawanie pakietów</td>
                            </tr>
                            <tr>
                                <td>06.09.2025 (Sobota)</td>
                                <td>-</td>
                                <td>Start Classico,<br/> Finish Gigante, <br/>Dekoracja Gigante, <br/>Before Party Piccolo - wydawanie pakietów <br/>Finish Classico,<br/></td>
                            </tr>
                            <tr>
                                <td>07.09.2025 (Niedziela)</td>
                                <td>-</td>
                                <td>Start Piccolo,<br/> Dekoracja Classico,<br/> Witanie finisherów, <br/> Dekoracja Piccolo</td>
                            </tr>
                        </tbody>
                    </table>
                </StyledTable>
            </Section>

            <Section>
                <h2>🏁 Meta & Pit-Stopy</h2>
                <p>📍 Meta: Wspólna dla wszystkich dystansów w **Mielniku.</p>
                <p>✅ 2 pit-stopy dla Gigante.</p>
                <p>✅ 1 pit-stop dla Classico i Piccolo.</p>
            </Section>

            <Section>
                <h2>🏆 Nagrody</h2>
                <p>🥇 Statuetki dla zwycięzców.</p>
                <p>🎁 Nagrody losowane wśród wszystkich uczestników – bo każdy, kto stanie na starcie, już jest zwycięzcą!</p>
            </Section>

            <Section>
                <h2>🚴‍♂️ Czy zabrać rodzinę?</h2>
                <p>🌿 Dolina Bug to raj dla turysty!</p>
                <p>🏡 Szeroka baza noclegowa – od hoteli po klimatyczne agroturystyki.</p>
                <p>🎭 Atrakcje turystyczne – muzea, urokliwe miejsca, dziewicza natura i oaza spokoju nad Bugiem.</p>

            </Section>
            <GallerySection>
            <GalleryGrid>
                <StaticImage src="../images/zawodyG/gallery1.jpg" alt="Ultra Race 1" className="gallery-item" />
                <StaticImage src="../images/zawodyG/gallery2.jpg" alt="Ultra Race 2" className="gallery-item" />
                <StaticImage src="../images/zawodyG/gallery3.jpg" alt="Ultra Race 3" className="gallery-item" />
                <StaticImage src="../images/zawodyG/gallery4.jpg" alt="Ultra Race 4" className="gallery-item" />
                <StaticImage src="../images/zawodyG/gallery5.jpg" alt="Ultra Race 5" className="gallery-item" />
                <StaticImage src="../images/zawodyG/gallery6.jpg" alt="Ultra Race 6" className="gallery-item" />
                <StaticImage src="../images/zawodyG/gallery7.jpg" alt="Ultra Race 7" className="gallery-item" />
                <StaticImage src="../images/zawodyG/gallery8.jpg" alt="Ultra Race 8" className="gallery-item" />
            </GalleryGrid>
        </GallerySection>
        </Layout>
    );
}

export default Zawody;
